import React from 'react'

import { cn } from '../lib/helpers'
import styles from './categories.module.css'
import { small } from './typography.module.css'

const Categories = ({ categories }) => {
  return (
    <div className={styles.categories}>
      <h3 className={styles.categoriesHeadline}>Categories</h3>
      <ul>
        {categories.map(category => (
          <li
            className={cn(styles.category, small)}
            key={category._id}
          >{category.title}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Categories
