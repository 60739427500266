import React, { useEffect, useState } from 'react'

const BackToTopButtonWrapper = () => {
  const [Button, setButton] = useState(null)
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    const pageWrapper = document.querySelector('#gatsby-focus-wrapper')
    // * Detect if browser supports passive event listeners
    // * Learn more: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
    var supportsPassive = false
    try {
      /* eslint-disable getter-return */
      var opts = Object.defineProperty({}, 'passive', { get: function () { supportsPassive = true } })
      window.addEventListener('testPassive', null, opts)
      window.removeEventListener('testPassive', null, opts)
    } catch (e) { }

    const scrollEventListener = async () => {
      const scrolledPastViewportHeight = pageWrapper.scrollTop > window.innerHeight
      if (scrolledPastViewportHeight) {
        if (!Button) {
          // ! dynamically importing using es6 import is not possible yet:
          // ! https://github.com/gatsbyjs/gatsby/issues/7810
          const buttonPackage = require('./backToTopButton.js')
          const lazyButton = React.createElement(buttonPackage.default)
          setButton(lazyButton)
        }
        setShowButton(true)
      } else if (Button && !scrolledPastViewportHeight) {
        setShowButton(false)
      }
    }

    pageWrapper.addEventListener('scroll', scrollEventListener, supportsPassive ? { passive: true } : false)
    // * Fixes multiple event listeners being added on each Gatsby navigation
    // ? Only in Chrome Dev Tools: window.getEventListeners(pageWrapper)["scroll"]
    // * returned function will run before the component unmounts (similar to `componentWillUnmount`)
    return function cleanup () {
      pageWrapper.removeEventListener('scroll', scrollEventListener)
    }
  })

  return (
    <>
      {Button && showButton && Button}
    </>
  )
}

export default BackToTopButtonWrapper
