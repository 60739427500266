import React, { useEffect, useState } from 'react'

import styles from './progress-bar.module.css'

const ProgressBar = () => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const pageWrapper = document.querySelector('#gatsby-focus-wrapper')
    const footerHeight = document.querySelector('footer').clientHeight
    const asideOffset = window.matchMedia('(max-width: 674px)').matches ? document.querySelector('article aside').clientHeight : 0
    // * Detect if browser supports passive event listeners
    // * Learn more: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
    var supportsPassive = false
    try {
      /* eslint-disable getter-return */
      var opts = Object.defineProperty({}, 'passive', { get: function () { supportsPassive = true } })
      window.addEventListener('testPassive', null, opts)
      window.removeEventListener('testPassive', null, opts)
    } catch (e) { }

    function removeParticle (e) {
      e.srcElement.effect.target.remove()
    }

    function createParticle () {
      const particle = document.createElement('particle')
      document.body.appendChild(particle)
      let width = Math.floor(Math.random() * 30 + 8)
      let height = width
      const destinationX = (Math.random() - 0.5) * 300
      const destinationY = (Math.random() - 0.5) * 300
      const rotation = Math.random() * 520
      const delay = Math.random() * 200

      // secondary: hsl(166deg 100% 70%)
      // accent: hsl(256deg 100% 65%)
      var color = `hsl(${Math.random() * 90 + 30}, 100%, 47%)`
      particle.style.boxShadow = `0 0 ${Math.floor(Math.random() * 10 + 10)}px ${color}`
      particle.style.background = color
      particle.style.borderRadius = '50%'
      width = height = Math.random() * 5 + 4

      particle.style.width = `${width}px`
      particle.style.height = `${height}px`
      const animation = particle.animate([
        {
          transform: 'translate(-50%, -50%) translate(0px, 0px) rotate(0deg)',
          opacity: 1
        },
        {
          transform: `translate(-50%, -50%) translate(${destinationX}px, ${destinationY}px) rotate(${rotation}deg)`,
          opacity: 1
        }
      ], {
        duration: Math.random() * 1000 + 2000,
        easing: 'cubic-bezier(0, .9, .57, 1)',
        delay: delay
      })
      animation.onfinish = removeParticle
    }

    function showParticles () {
      const amount = 60
      for (let i = 0; i < amount; i++) {
        createParticle()
      }
    }

    const scrollEventListener = () => {
      const newProgress = Math.min(
        Number(
          (
            pageWrapper.scrollTop /
            (pageWrapper.scrollHeight - pageWrapper.offsetHeight - footerHeight - asideOffset)
          ).toFixed(3)
        ),
        1
      )

      setProgress(newProgress)
      if (newProgress === 1 && progress !== 1) {
        showParticles()
      }
    }

    pageWrapper.addEventListener('scroll', scrollEventListener, supportsPassive ? { passive: true } : false)
    // * Fixes multiple event listeners being added on each Gatsby navigation
    // ? Only in Chrome Dev Tools: window.getEventListeners(pageWrapper)["scroll"]
    // * returned function will run before the component unmounts (similar to `componentWillUnmount`)
    return function cleanup () {
      pageWrapper.removeEventListener('scroll', scrollEventListener)
    }
  })

  return (
    <div className={styles.progressBar} style={{ transform: `scaleX(${progress})` }} />
  )
}

export default ProgressBar
