import React from 'react'
import { RiInstagramFill } from 'react-icons/ri'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import OutboundLink from './block-content/outboundLink'
import DevToBadge from './dev.to-badge'
import styles from './role-list.module.css'
import TwitterBadge from './twitter-badge'

function ucfirst (str) {
  return `${str.substr(0, 1).toUpperCase()}${str.substr(1)}`
}

function RoleList ({ items, title }) {
  return (
    <div className={styles.root}>
      <h2 className={styles.headline}>{title}</h2>
      <ul className={styles.list}>
        {items.map(item => (
          <li key={item._key} className={styles.listItem}>
            <div>
              <div className={styles.avatar}>
                {item.person && item.person.image && item.person.image.asset && (
                  <img
                    src={imageUrlFor(buildImageObj(item.person.image))
                      .width(100)
                      .height(100)
                      .fit('crop')
                      .url()}
                    loading='lazy'
                    alt="Author's profile picture"
                  />
                )}
              </div>
            </div>
            <div className={styles.detailsWrapper}>
              <div>
                <strong>{(item.person && item.person.name) || <em>Missing</em>}</strong>
              </div>
              {item.roles && (
                <div>
                  {item.roles.map((role, idx) => {
                    switch (true) {
                      case idx === 0:
                        return <span key={role}>{ucfirst(role)}</span>
                      case idx === item.roles.length - 1:
                        return <span key={role}> & {role}</span>
                      default:
                        return <span key={role}>, {role}</span>
                    }
                  })}
                </div>
              )}
              {item.person && item.person.twitter &&
                <TwitterBadge twitter={item.person.twitter} name={item.person.name} />}
              {item.person && item.person.devto &&
                <DevToBadge devto={item.person.devto} name={item.person.name} />}
              {item.person && item.person.instagram &&
                <OutboundLink
                  href={`https://www.instagram.com/${item.person.instagram}/`}
                  title={`Visit ${item.person.name}'s profile on Instagram`}
                >
                  <RiInstagramFill size={22} />
                </OutboundLink>}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default RoleList
