import { graphql } from 'gatsby'
import React from 'react'

import Article from '../components/article'
import Seo from '../components/seo'
import Layout from '../containers/layout'
import {
  getBlogPostUrl,
  getStringArrayFromCategories,
  getStringFromBlocks
} from '../lib/helpers'

const BlogPostTemplate = ({ data, errors }) => {
  const {
    title,
    excerpt,
    categories,
    publishedAt,
    _updatedAt,
    _rawBody,
    slug,
    mainImage,
    authors
  } = data && data.post
  const url = getBlogPostUrl(publishedAt, slug)

  return (
    <Layout>
      {data.post && (
        <>
          <Seo
            title={title || 'Untitled'}
            description={getStringFromBlocks(excerpt)}
            keywords={getStringArrayFromCategories(categories)}
            url={url}
            image={mainImage && mainImage.asset.url}
            author={authors[0].person}
            publishedAt={publishedAt}
            updatedAt={_updatedAt}
            articleBody={getStringFromBlocks(_rawBody)}
          />
          <Article {...data.post} url={url} />
        </>
      )}
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      _updatedAt
      _rawExcerpt
      excerpt {
        children {
          text
        }
      }
      categories {
        _id
        title
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          url
        }
        alt
        _rawCaption
      }
      title
      slug {
        current
      }
      _rawBody
      authors {
        _key
        person {
          name
          twitter
          devto
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
              url
            }
          }
        }
        roles
      }
    }
  }
`
