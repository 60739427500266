import React from 'react'
import { FaArrowUp } from 'react-icons/fa'

import styles from './backToTopButton.module.css'

const BackToTopButton = () => {
  const onClickHandler = () => {
    document.querySelector('#gatsby-focus-wrapper').scrollTo(0, 0)
  }

  return (
    <button
      onClick={onClickHandler}
      title='Back to top'
      className={styles.button}
    >
      <FaArrowUp />
    </button>
  )
}

export default BackToTopButton
