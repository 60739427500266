import React from 'react'
import { FaDev } from 'react-icons/fa'

import OutboundLink from './block-content/outboundLink'

const DevToBadge = ({ devto, name }) => {
  return (
    <OutboundLink href={`https://dev.to/${devto}`} title={`Visit ${name}'s DEV Community Profile`}>
      <FaDev size='1.5em' />
    </OutboundLink>
  )
}

export default DevToBadge
