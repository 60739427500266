import { differenceInDays, distanceInWords, format } from 'date-fns'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import {
  buildImageObj,
  getStringArrayFromCategories,
  getStringFromBlocks
} from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import styles from './article.module.css'
import BackToTopButtonWrapper from './backToTopButtonWrapper'
import BlockContent from './block-content'
import BlockText from './block-text'
import Categories from './categories'
import ProgressBar from './progress-bar'
import RoleList from './role-list'
import ShareButtons from './shareButtons'
import { fadedText } from './typography.module.css'

function Article ({ _rawBody, _rawExcerpt, authors, categories, title, mainImage, publishedAt, _updatedAt, url }) {
  useEffect(() => {
    // ? Declare async functions in useEffect
    const importCodeHighlighter = async () => {
      // ! dynamically importing using es6 import is not possible yet:
      // ! https://github.com/gatsbyjs/gatsby/issues/7810
      require('../lib/prism.min.js')
      require('./../lib/prism.css')
    }
    importCodeHighlighter()
  }, [])

  const mainImageUrl = imageUrlFor(buildImageObj(mainImage))
    .width(1200)
    .height(Math.floor((9 / 16) * 1200))
    .fit('crop')
    .url()

  return (
    <>
      <Helmet>
        <link rel='preload' as='image' href={mainImageUrl} importance='high' />
      </Helmet>
      <ProgressBar />
      {typeof window !== 'undefined' && (
        <React.Suspense fallback={<></>}>
          <BackToTopButtonWrapper />
        </React.Suspense>
      )}
      <article className={styles.root}>
        {(mainImage && mainImage.asset) && (
          <div className={styles.mainImage}>
            <img
              src={mainImageUrl}
              alt={mainImage.alt || ''}
            />
            {mainImage._rawCaption && (
              <div className={styles.mainImageCaption}>
                <BlockContent blocks={mainImage._rawCaption} />
              </div>)}
          </div>
        )}
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawExcerpt && (
              <div className={fadedText}>
                <BlockText blocks={_rawExcerpt} />
              </div>
            )}
            {_rawBody && <BlockContent blocks={_rawBody} />}
          </div>
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do YYYY')}
              </div>
            )}
            {(_updatedAt && differenceInDays(new Date(_updatedAt), new Date(publishedAt)) > 0) && (
              <div className={styles.updatedAt}>
                Updated at:{' '}
                {differenceInDays(new Date(_updatedAt), new Date()) > 3
                  ? distanceInWords(new Date(_updatedAt), new Date())
                  : format(new Date(_updatedAt), 'MMMM Do YYYY')}
              </div>
            )}
            {authors && <RoleList items={authors} title='Authors' />}
            {categories && !!categories.length && <Categories categories={categories} />}
            <ShareButtons
              url={url}
              title={title}
              categories={getStringArrayFromCategories(categories)}
              summary={getStringFromBlocks(_rawExcerpt)}
              twitterAuthor={authors[0].person.twitter}
            />
          </aside>
        </div>
      </article>
    </>
  )
}

export default Article
