import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share'

import styles from './shareButtons.module.css'
import { base } from './typography.module.css'

const siteQuery = graphql`
  query SiteQuery {
    sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      domain
    }
  }
`

const ShareButtons = ({ url, title, categories, twitterAuthor, summary }) => {
  return (
    <StaticQuery
      query={siteQuery}
      render={data => {
        const pageUrl = data.sanitySiteSettings.domain + url

        return (
          <div className={styles.shareButtons}>
            <h3 className={base}>Share this page</h3>

            <FacebookShareButton
              url={pageUrl}
              quote={`${title}: ${summary}`}
            >
              <FacebookIcon round size={42} />
            </FacebookShareButton>

            <TwitterShareButton
              url={pageUrl}
              title={title}
              via={twitterAuthor}
              hashtags={categories}
            >
              <TwitterIcon round size={42} />
            </TwitterShareButton>

            <LinkedinShareButton
              url={pageUrl}
              title={title}
              summary={summary}
            >
              <LinkedinIcon round size={42} />
            </LinkedinShareButton>
          </div>
        )
      }}
    />
  )
}

export default ShareButtons
